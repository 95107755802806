import {connect} from "react-redux";
import React, {Component} from "react";
import {formatQuery} from "../controllers/utilities/string-utils";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import cookie from "react-cookies";
import Lambda from "../controllers/utilities/aws-lambda";
import KaisakuApi, {getFullPath} from "../controllers/kaisaku-api";
import {navigate} from "gatsby";
import {Button, Image, Grid} from "semantic-ui-react";
import continue1 from "../images/main/continue1.png";
import confrim from "../images/main/confrim.png";
import NavigationBar from "../components/forms/navigation-bar";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false
        };
        // rejectIfAnyNull(this.props.session, this.props.transactionId);
    }

    componentWillUnmount() {
        // window.removeEventListener('resize', this.getDirection);
    }

    getDirection = () => {
        this.setState({ state: this.state });
    }

    onContinue() {
        let {pendingTransactionId, newAccessToken, newGameId, newAttributionId} = formatQuery(this.props.location.search);
        if (((newAccessToken === undefined) && (newAccessToken !== null)) || ((newGameId === undefined) && (newGameId !== null))){
            newAccessToken = cookie.load("_accessToken");
            newGameId = cookie.load("_gameId");
            newAttributionId = cookie.load("_attributionId");
            pendingTransactionId = cookie.load("_transactionId");
        }
        if ((newAccessToken !== undefined) && (newGameId !== undefined) && (newAccessToken !== null) && (newGameId !== null)){
            let platform = cookie.load("_platform");
            const url = `/confirm-purchase/?gameId=${newGameId}&attributionId=${newAttributionId}&transactionId=${pendingTransactionId}&accessToken=${newAccessToken}&platform=${platform}`;
            // console.log(url);
            window.location.href = url;
        }else {
            navigate("/confirm-purchase");
        }
    }

    async componentDidMount() {
        // 获取最后一单，如果没有，显示返回键
        const data = await KaisakuApi.getLastTransaction(this.props.session.accessToken);
        if ((data.data.transactionId) && (data.data.transactionId !== "")){
            navigate(`/credit-purchase-result/?transactionId=${data.data.transactionId}`);                        
        }else{
            this.state.ready = true;
            this.setState({
                ready: true
            });
        }
        // 有，跳转到帐号详情页
        // 无，显示没有的提示
        // window.addEventListener('resize', this.getDirection);
    }

    render() {
        let showReturnBtn = cookie.load("_showReturnBtn");
        const mode = detectOrient();
        return (
            <>
            {(this.state.ready) && (<div className="whiteDiv" textAlign='center' style={{
                width: "100%",
                minHeight: "100vh",
                textAlign: "center",
                margin: "0 auto",
                
            }} >
<NavigationBar mode={mode} platform={"h5"} showLogo={1} showTitle={0} onBack={async () => {
                            if (showReturnBtn === "1") {
                                navigate(`/sdk-callback`);
                            } else {
                                this.onContinue();
                            }
                        }}/>

                <div style={{ Width: "100%", height: "100vh", paddingTop: "80px", margin: "0 auto" }}>
                    <div style={{ padding: "3vh" }}>

                        <Grid textAlign='center' style={{
                            margin: '0',
                            width: '100%',
                            backgroundColor: "transparent",
                        }} verticalAlign='top'>

                            
                                    <Grid.Column style={{margin:"0 auto"}}>
                                    <div style={{width:"100%", fontSize:"1rem", 
                                    backgroundColor:"#fff", textAlign:"left", padding:"8px", color:"#888", borderRadius: '0.5rem',
                                    boxShadow: '0 1px 1px 0 #888, 0 0 0 1px #888'}}>
                                <div style={{width:"100%", paddingTop:"10px", paddingBottom:"10px", textAlign:"center"}}

                                >您還沒有訂單信息~快來增值吧！
                                </div>

                            </div>
                                    </Grid.Column>
                            
                            
                        </Grid>
                    </div></div>
            </div>)}
            </>            
        );
    }


});

function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "";
    }
}